import globalConfig from "@/globalConfig.js";
import utils from "@/globalUtil.js";

const GlobalController = {
    isLoaded: false,
    isLoading: false,
    get_headers(method = 'get', data = null) {
        let ret = {
            method: method,
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }

        if (method == 'post' && data) {
            ret.body = JSON.stringify(data)
        }

        return ret
    },
    api_call(api_url, result, reject) {
        // Our simplified Backend data call, result or reject.
        // We might reject due an error on an internal error
        fetch(globalConfig.getApiPath(api_url), this.get_headers())
            .then((response) => response.json())
            .then((data) => {
                if (data.status != 'success') {
                    if (reject) return reject(data.error_msg)
                    //toast.error(data.error_msg)
                    return
                }

                return result(data)
            })
            .catch((error) => {
                if (reject) return reject(error)
                //this.get('alert').setDanger('Error', 'There was a problem accessing this server ' + error)
            })
    },
    get_currentuser(user_callback, error_callback, invalidate = false) {
        // Loads the user from the backend and stores it in localStorage
        // We provide a helper to invalidate the user in case we really want to reload it
        // First time you login it will call the backend.
        if (!invalidate) {
            const userData = utils.getLocalStorageUser();
            if (userData != null) {
                user_callback(userData);
                return;
            }
        }

        this.api_call(
            '/user/get/me',
            (data) => {
                console.log("CONTROLLER: Loaded user " + data.user);
                localStorage.setItem("userData", JSON.stringify(data.user));
                user_callback(data.user);
            },
            (error) => {
                console.log("CONTROLLER: Error loading user " + error);
                this.get('alert').setDanger('Error', 'Unable to connect to the server. Please try again later.')
                error_callback(error);
            }
        )
    },
    save_json_update(api_entry, data, result, reject) {
        let api_url = globalConfig.getApiPath(api_entry);
        let headers = this.get_headers('post', data);
        fetch(api_url, headers)
            .then((response) => response.json())
            .then((data) => {
                if (data.status != 'success') {
                    if (reject) return reject(data.error_msg)
                }

                if (result) return result(data)
            })
            .catch((error) => {
                if (reject) return reject(error)
                this.get('alert').setDanger('Error', 'Unable to connect to the server. please wait...')
            })
    },
    update_currentuser_key(key, value, user_callback, error_callback) {
        this.save_json_update(
            '/user/set/' + key,
            { value: value },
            (data) => {
                console.log("CONTROLLER: Loaded user " + data.user);
                localStorage.setItem("userData", JSON.stringify(data.user));
                user_callback(data.user);
            },
            (error) => {
                console.log("CONTROLLER: Error loading user " + error);
                error_callback(error);
            }
        )
    },

}

export default GlobalController;